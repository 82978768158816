@import './src/assets/scss/_vars_and_mixins.sass';


.about-us-upper {
  background: linear-gradient(90deg, $deepBlue 56.5%, $lightGreyBg 51%);
  @include s {
    background: none; }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    @include s {
      flex-direction: column-reverse; } } }


.company-description {
  width: 40.4%;
  padding-bottom: 66px;
  @include l {
    padding-bottom: 45px; }
  @include s {
    width: 100%;
    background: $deepBlue;
    @include overflow-from-container;
    padding-bottom: 27px; }

  h3 {
    @include white-header;
    padding: 100px 0 52px;
    @include l {
      padding: 77px 0 52px; }
    @include s {
      padding: 50px 0 20px; } }

  p {
    @include basic-white-text;
    padding-bottom: 33px;
    @include s {
      padding-bottom: 25px; } } }

.countries {
  width: 32.5%;
  @include l {
    width: 39%; }
  @include s {
    width: 100%;
    background: $lightGreyBg;
    @include overflow-from-container;
    padding-bottom: 60px; }

  h4 {
    @include black-smaller-header;
    padding: 112px 0 29px;
    z-index: 4;
    position: relative;
    @include l {
      padding: 85px 0 28px; }
    @include s {
      max-width: 19em;
      padding: 60px 0 20px; } } }

.countries-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;
  max-height: 130px;
  z-index: 4;
  position: relative;

  p {
    @include basic-black-text;
    line-height: 43px;
    background-repeat: no-repeat;
    padding-left: 46px;
    background-position: 0 2px;
    width: 38.5%;
    box-sizing: content-box;
    @include l {
      line-height: 38px; }
    @include s {
      line-height: 36px;
      background-size: auto 83%;
      padding-left: 39px;
      width: 42%; }

    &.canada {
      background-image: url(#{$imagesPath}/flags/canada.svg); }

    &.the-usa {
      background-image: url(#{$imagesPath}/flags/the-usa.svg); }

    &.germany {
      background-image: url(#{$imagesPath}/flags/germany.svg); }

    &.great-britain {
      background-image: url(#{$imagesPath}/flags/great-britain.svg); }

    &.israel {
      background-image: url(#{$imagesPath}/flags/israel.svg); }

    &.kazakhstan {
      background-image: url(#{$imagesPath}/flags/kazakhstan.svg); } } }


.about-us-bottom {
  background: linear-gradient(90deg, $black 57%, $lightGreyBg 51%);
  @include l {
    background: linear-gradient(90deg, $black 57%, $lightGreyBg 51%); }
  @include m {
    margin-bottom: -70px; }
  @include s {
    background: none;
    margin-bottom: 0;
    padding-bottom: 20px; }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 100px;
    @include l {
      margin-bottom: 70px; }
    @include m {
      margin-bottom: 20px; } } }

.contact-us {
  padding: 100px 0;
  width: 50%;
  box-sizing: border-box;
  @include l {
    padding: 75px 0; }
  @include s {
    width: 100%;
    background: $black;
    box-sizing: initial;
    @include overflow-from-container;
    padding-bottom: 50px; }

  h4 {
    @include white-header;
    padding-bottom: 50px;
    @include s {
      padding: 40px 0 20px; } }

  .form {
    width: 81.5%;
    box-sizing: border-box;
    @include m {
      width: 91%; }
    @include s {
      width: 100%; } } }

.partners {
  h4 {
    @include black-header;
    max-width: 9em;
    @include s {
      padding: 50px 0 15px;
      max-width: none; } } }


.partners {
  background-color: $white;
  position: relative;
  transform: translateY(0px);
  width: 41%;
  @include l {
    transform: translateY(15px);
    width: 45%; }
  @include m {
    transform: translateY(9px); }
  @include s {
    width: 100%;
    transform: none;
    @include overflow-from-container; }

  span {
    display: block;
    width: 100vw;
    height: 116%;
    background-color: $white;
    position: absolute;
    left: -127px;
    top: -100px;
    z-index: 1;
    @include l {
      left: -35px;
      top: -80px;
      height: 1025px; }
    @include m {
      top: -60px;
      height: 965px; }
    @include s {
      display: none; } } }

.partners-inner {
  position: relative;
  z-index: 10;

  .partners-items-box {
    display: flex;
    flex-wrap: wrap;

    p {
      @include partners-names-text;
      width: 33.3%;
      padding: 100px 22px 20px;
      max-height: 127px;
      box-sizing: border-box;
      background-position: 43% 33%;
      background-repeat: no-repeat;
      cursor: pointer;
      @include l {
        padding: 72px 7px 20px; }

      &.partner-item-toptal {
        background-image: url(#{$imagesPath}/partners/toptal.svg);
        background-position: center;
        padding-top: 110px;
        @include l {
          background-size: 93%;
          background-position: 50% 14%; } }

      &.partner-item-jetbrains {
        background-image: url(#{$imagesPath}/partners/jetbrains.svg);
        background-position: center;
        padding-top: 110px;
        @include l {
          background-size: 37%;
          background-position: 50% 5%; } }

      &.partner-item-python-software-foundation {
        background-image: url(#{$imagesPath}/partners/python-software-foundation.svg);
        background-position: center;
        line-height: 1.3;
        padding-top: 118px;
        @include l {
          background-size: 90%;
          background-position: 50% 10%; } }

      &.partner-item-zensoft {
        background-image: url(#{$imagesPath}/partners/zensoft.svg);
        @include l {
          background-size: 90%;
          background-position: 50% 34%; } }

      &.partner-item-clutch {
        background-image: url(#{$imagesPath}/partners/clutch.svg);
        background-position: center;
        padding-top: 110px;
        @include l {
          background-size: 65%;
          background-position: 50% 34%; } }

      &.partner-item-tengry {
        background-image: url(#{$imagesPath}/partners/tengry.svg);
        background-size: 70%;
        background-position: center;
        padding-top: 110px;
        @include l {
          background-size: 70%;
          background-position: 50% 32%; } }

      &.partner-item-sametrica {
        background-image: url(#{$imagesPath}/partners/sametrica.svg);
        background-position: center;
        padding-top: 110px;
        @include l_1300 {
          background-size: contain; }
        @include l {
          background-size: 87%;
          background-position: 50% 26%; } }

      &.partner-item-peklo {
        background-image: url(#{$imagesPath}/partners/peklo.svg);
        @include l {
          background-size: 50%;
          background-position: 50% 21%; } }

      &.partner-item-b12 {
        background-image: url(#{$imagesPath}/partners/b12.svg);
        background-position: center;
        padding-top: 110px;
        @include l {
          background-size: 46%;
          background-position: 50% 27%; } }

      &.partner-item-backflow-prevention-services {
        background-image: url(#{$imagesPath}/partners/backflow-prevention-services.svg);
        background-position: center;
        line-height: 1.3;
        padding-top: 118px;
        @include l_1300 {
          background-size: contain; }
        @include l {
          background-size: 87%;
          background-position: 50% 28%; } }

      &.partner-item-xis7 {
        background-image: url(#{$imagesPath}/partners/xis7.svg);
        background-position: center;
        padding-top: 110px;
        @include l {
          background-size: 30%;
          background-position: 50% 27%; } }

      &.partner-item-heartbeatai {
        background-image: url(#{$imagesPath}/partners/heartbeatai.svg);
        background-position: center;
        padding-top: 110px;
        @include l_1300 {
          background-size: contain; }
        @include l {
          background-size: 87%;
          background-position: 50% 27%; } }

      &.partner-item-techwomen {
        background-image: url(#{$imagesPath}/partners/techwomen.svg);
        background-position: center;
        padding-top: 110px;
        @include l_1300 {
          background-size: contain; }
        @include l {
          background-size: 87%;
          background-position: 50% 31%; } }

      &.partner-item-usaid {
        background-image: url(#{$imagesPath}/partners/usaid.svg);
        background-position: center;
        padding-top: 110px;
        @include l {
          background-size: 76%;
          background-position: 50% 30%; } }

      &.partner-item-udemy {
        background-image: url(#{$imagesPath}/partners/udemy.svg);
        background-position: center;
        padding-top: 110px;
        @include l {
          background-size: 75%;
          background-position: 50% 30%; } }

      &.partner-item-lalafo {
        background-image: url(#{$imagesPath}/partners/lalafo.png);
        background-position: center;
        padding-top: 110px;
        @include l {
          background-size: 58%;
          background-position: 50% 35%; } }

      &.partner-item-pluralsite {
        background-image: url(#{$imagesPath}/partners/pluralsite.svg);
        background-position: center;
        padding-top: 110px;
        @include l {
          background-size: 82%;
          background-position: 50% 34%; } }

      &.partner-item-topdeveloper {
        background-image: url(#{$imagesPath}/partners/Top_developers.svg);
        background-position: center;
        padding-top: 110px;
        @include l {
          background-size: 76%;
          background-position: 50% 34%; } }

      &.partner-item-goodfirms {
        background-image: url(#{$imagesPath}/partners/Good-firms.webp);
        background-size: 92%;
        background-position: center;
        padding-top: 110px;
        @include l {
          background-position: 50% 34%; } }

      &.partner-item-techreviewer {
        background-image: url(#{$imagesPath}/partners/Techreviewer.svg);
        background-size: 84%;
        background-position: center;
        padding-top: 110px;
        @include l {
          background-position: 50% 34%; } }

      &.partner-item-python-development-companies {
        background-image: url(#{$imagesPath}/partners/Python-developer.png);
        background-size: 50%;
        line-height: 1.3;
        padding-top: 118px;
        background-position: center;
        @include l {
          background-position: 50% 34%; } }

      &.partner-item-app-development-companies {
          background-image: url(#{$imagesPath}/partners/app-development-companies.png);
          background-size: 60%;
          padding-top: 156px;
          background-position: center;
          @include l {
            background-position: 50% 34%; } }

      &.partner-item-reactjs {
        background-image: url(#{$imagesPath}/partners/banner-award-2023-react-js.png);
        background-size: 80%;
        padding-top: 136px;
        background-position: center;
        @include l {
          background-position: 50% 34%; } }

      &.partner-item-web-development {
        background-image: url(#{$imagesPath}/partners/banner-award-2023-web-dev.png);
        background-size: 80%;
        padding-top: 136px;
        background-position: center;
        @include l {
          background-position: 50% 34%; } }

      &.partner-item-mobile-app-development {
        background-image: url(#{$imagesPath}/partners/banner-award-2023-web-mob-dev.png);
        background-size: 80%;
        padding-top: 136px;
        background-position: center;
        @include l {
          background-position: 50% 34%; } }

      &.partner-item-global-awards {
        background-image: url(#{$imagesPath}/partners/global_award_2023.png);
        background-size: 80%;
        padding-top: 156px;
        background-position: center;
        @include l {
          background-position: 50% 34%; } }

      &.partner-item-top-clutch {
        background-image: url(#{$imagesPath}/partners/top_clutch.co_golang_developers_2023_award.png);
        background-size: 50%;
        padding-top: 156px;
        background-position: center;
        @include l {
          background-position: 50% 34%; } } } } }

@media screen and (max-width: 1200px) {
  .partners span {
    height: 110%; }

  .partners-inner .partners-items-box p.partner-item-python-development-companies {
    padding-top: 90px;
    line-height: 1.2; }

  .partners-inner .partners-items-box p.partner-item-app-development-companies {
    padding-top: 90px;
    line-height: 1.2; } }

@media screen and (max-width: 720px) {
  .partners-inner .partners-items-box p.partner-item-python-development-companies {
    background-size: 26%;
    padding-top: 84px; }

  .partners-inner .partners-items-box p.partner-item-app-development-companies {
    background-size: 26%;
    padding-top: 84px; }

  .partners {
    padding-bottom: 30px; }

  .newcomers-right {
    display: none; }
  .newcomers-right .join-us {
    display: none!important; } }

@media screen and (max-width: 576px) {
  .header-text {
    font-size: 15px; } }

@media screen and (max-width: 480px) {
  .partners-inner .partners-items-box p.partner-item-python-development-companies {
    padding-top: 70px;
    background-size: 36%; }

  .partners-inner .partners-items-box p.partner-item-app-development-companies {
    padding-top: 70px;
    background-size: 36%; } }
