@import './src/assets/scss/_vars_and_mixins.sass';

.lets-talk-block {
  background-color: $deepBlue;
  padding: 100px 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
  @include l {
    padding: 79px 0; }
  @include s {
    padding: 50px 0 128px; }

  &::after {
    content: '';
    width: 1700px;
    height: 1700px;
    display: block;
    top: -110px;
    right: -750px;
    z-index: -1;
    opacity: 0.2;
    background-image: url(#{$imagesPath}/attractor-logo-stroke.svg);
    background-repeat: no-repeat;
    animation: mymove 50s infinite linear;
    background-size: 92%;
    position: absolute; }


  .container {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    @include s {
      position: relative; } } }

.lets-talk-info {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include s {
    width: 100%; }

  h4 {
    @include header($white);
    max-width: 10em;
    padding-bottom: 50px; }

  .lets-talk-contact {
    padding: 0 0 0 30px;
    background-repeat: no-repeat;
    background-position: 0 8px;
    cursor: pointer; }

  .link-box {
    padding: 45px 0 50px;
    display: flex;
    flex-direction: row; }

  a {
    @include basic-white-text;
    font-weight: 400;
    line-height: 32px;
    text-decoration: none;
    margin-top: 8px;

    &:hover {
      text-decoration: underline; } } }

.lets-talk-phone {
  background-image: url(#{$imagesPath}/phone.svg);
  @include l {
    background-position: 0 2px !important; } }

.lets-talk-tg {
  margin-top: 10px;
  background-image: url(#{$imagesPath}/telegram.svg);
  @include l {
    background-position: 0 5px !important; } }

.footer-signature {
  @include s {
    position: absolute;
    bottom: -70px;
    left: 50%;
    transform: translateX(-50%); } }

.lets-talk-form {
  width: 50%;
  box-sizing: border-box;
  @include s {
    width: 100%; } }


@media screen and (max-width: 720px) {
  .btnWidth > button {
    width: 100%; }

  .text_width {
    width: 100%;
    text-align: center; } }
