@import './src/assets/scss/_vars_and_mixins.sass';

.case-study-block {
  padding: 0 10px;
  background-color: $lightGreyBg;
  @include s {
    padding: 0 5px; }

  .container {
    padding-right: 10px;
    padding-left: 10px; }

  h4 {
    @include header($black);
    padding: 10px 0 40px;
    @include s {
      padding: 0 0 10px; } } }

.cases-box {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box; }

.cases-item {
  width: 33.3%;
  box-sizing: border-box;
  padding: 10px;
  @include s {
    width: 50%;
    padding: 5px; }

  a {
    background-color: $deepBlue;
    display: block;
    height: 308px;
    text-decoration: none;
    box-sizing: border-box;
    padding: 0 60px;
    background-size: auto 100%;
    background-position: center;
    transition: all 150ms;
    @include l {
      padding: 0 27px; }
    @include m {
      height: 31vw; }

    @include s {
      padding: 0 15%;
      height: 45vw; }

    &:hover {
      background-size: auto 120%;
      transition: all 300ms; }

    h5 {
      @include medium-header($white);
      text-align: center;
      padding-top: 170px;
      background-repeat: no-repeat;
      background-position: center 50px;
      @include s {
        padding-top: 176px;
        background-position: center 65px;
        background-size: 45% auto;
        max-width: 30em; }

      @include xs {
        padding-top: 65%;
        background-position: center 16px;
        background-size: 50% auto; } } } }

.cases-link-1 {
  background-image: url(#{$imagesPath}/cases/bg/sametrica.png);

  h5 {
    background-image: url(#{$imagesPath}/cases/icons/mvp-for-startups.svg); } }

.cases-link-2 {
  background-image: url(#{$imagesPath}/cases/bg/navigation.png);

  h5 {
    background-image: url(#{$imagesPath}/cases/icons/navigation-and-discovery-apps.svg); } }

.cases-link-3 {
  background-image: url(#{$imagesPath}/cases/bg/xis7.png);

  h5 {
    background-image: url(#{$imagesPath}/cases/icons/vr-for-enterprise.svg); } }

.cases-link-4 {
  background-image: url(#{$imagesPath}/cases/bg/garder.png);

  h5 {
    background-image: url(#{$imagesPath}/cases/icons/saas-systems.svg); } }

.cases-link-5 {
  background-image: url(#{$imagesPath}/cases/bg/tengriwallet.png);

  h5 {
    background-image: url(#{$imagesPath}/cases/icons/fintech-application-development.svg); } }

.cases-link-6 {
  background-image: url(#{$imagesPath}/cases/bg/marketplace.png);

  h5 {
    background-image: url(#{$imagesPath}/cases/icons/e-commerce-systems-and-emarketplaces.svg); } }

.cases-link-7 {
  background-image: url(#{$imagesPath}/cases/bg/heartbeat.png);

  h5 {
    background-image: url(#{$imagesPath}/cases/icons/social-networks-development.svg); } }

.cases-link-8 {
  background-image: url(#{$imagesPath}/cases/bg/business-inttelligence-systems.png);

  h5 {
    background-image: url(#{$imagesPath}/cases/icons/business-intelligence-systems.svg); } }

.cases-link-9 {
  background-image: url(#{$imagesPath}/cases/bg/hrm.png);

  h5 {
    background-image: url(#{$imagesPath}/cases/icons/hrm-and-crm.svg); } }
