@import './src/assets/scss/_vars_and_mixins.sass';

.case-studies-page-promo {
  min-height: 489px !important;
  @include m {
    min-height: 400px !important; } }


.case-studies-page-inner {
  margin-top: 50px;
  background-color: $lightGreyBg;
  padding-top: 40px;
  @include m {
    padding-top: 30px;
    padding-bottom: 50px; }

  .container > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;

    .side-block {
      width: 30%;
      @include m {
        display: none; } }

    .contents-block {
      max-width: 67%;
      @include m {
        max-width: 100%; } } } }
