@import "src/assets/scss/vars_and_mixins";

.conf-text {
  font-family: $Oswald;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 0.005em; }

.conf-images {
  > * {
    display: inline-block;
    height: 170px;
    width: 350px;
    background-size: contain;
    background-repeat: no-repeat;
    @include s {
      background-position: center center;
      margin: 0 !important;
      width: 70vw;
      height: 130px; } }


  .pycon-svg {
    background-image: url(#{$imagesPath}/conferences/pycon.svg); }

  .jscon-svg {
    background-image: url(#{$imagesPath}/conferences/jscon.svg); } }
