@import './src/assets/scss/_vars_and_mixins.sass';


.scroll-spy-menu {
  position: sticky;
  top: 100px;

  list-style: none;
  margin: 0 100px 0 0;
  height: 100vh;

  overflow-x: scroll;
  overflow-y: auto; }

.menu-item-section {
  padding: 0 0 0 43px;
  margin: 0 30px 24px 0;
  @include l_1300 {
    margin: 0 30px 8px 0; }

  > a {
    @include menu-sections-text;
    position: relative;
    text-decoration: none;
    width: 100%;
    display: block;
    z-index: 3;
    transition: color 100ms ease-in-out;

    &:hover {
      color: $black;

      > span {
        background-color: $green; } }

    > span {
      z-index: -1 !important;
      transition: background-color 100ms ease-in-out; } } }


.menu-section-inactive {
  > a {
    color: #7B7B7B;

    > span {
      @include grey-line(13px, -40px);
      @include l {
        @include grey-line(10px, -40px); } } } }

.menu-section-active {
  > a {
    color: $black;

    > span {
      @include green-line(13px, -40px);
      @include l {
        @include green-line(10px, -40px); } } } }


.project-list {
  padding: 0;
  margin: 0;

  li {
    display: block;
    height: 0;
    font-size: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 100ms ease-in-out;
    padding: 0 0 0 0;
    margin: 0 0 0 0;

    a {
      @include basic-text($darkgrey, 22px);
      line-height: 25px;

      &:hover {
        text-decoration: none;
        color: $black !important; } } } }


.open-menu ~ ul {
  li {
    height: auto !important;
    opacity: 1;
    transition: all 100ms ease-in-out;
    margin: 15px 0 15px 0;
    padding-left: 20px;

    &:before {
      content: '';
      width: 8px;
      height: 8px;
      display: block;
      position: relative;
      top: 17px;
      left: -15px; } } }


.menu-project-active {
  &:before {
    border: 2px solid $green; }

  a {
    color: $black !important; } }

.menu-project-inactive {
  &:before {
    border: 2px solid $lightgrey; }

  a {
    color: $darkgrey !important; } }

.scroll-spy-menu::-webkit-scrollbar {
  width: 0; }

.menu {
  padding-bottom: 100px;
  > li {
    list-style: none; } }
