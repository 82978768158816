@import './src/assets/scss/_vars_and_mixins.sass';

.processes {
  margin-top: 50px;
  padding: 40px 0 80px;
  position: relative;
  display: grid;
  grid-template-areas: "menu content";
  grid-template-columns: 1fr 2fr;
  @include m {
    grid-template-columns: 1fr 0;
    margin-top: 0;
    padding: 50px 0; }

  &-side {
    grid-area: menu;
    position: relative;
    @include s {
      display: none; } }

  &-menu {
    position: sticky;
    top: 0;
    list-style: none;
    padding: 0;

    &-top-pad {
      top: 100px; } }

  &-content {
    grid-area: content; } }

.processes-menu-item {
  @include basic-text($darkgrey, 20px);
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  margin-bottom: 20px;

  &::before {
    position: absolute;
    z-index: -1;
    left: -40px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    content: "";
    width: 50px;
    height: 5px;
    background-color: $ash; }

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer; }

  &-active {
    color: $black;

    &::before {
      background-color: $lightgreen; } } }

.process {
  background-color: $white;
  padding: 80px 200px 80px 120px;
  margin-bottom: 50px;
  position: relative;
  @include l {
    padding: 40px 100px 40px 60px; }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background-color: $ash; }

  &-active {
    &::before {
      background-color: $lightgreen; } }

  &-title {
    font-weight: bold;
    font-size: 30px;
    text-transform: uppercase; }

  &-description {
    @include basic-black-text; }

  &-step {
    @include basic-text($darkgrey, 22px);
    text-transform: uppercase;
    font-weight: bold; }

  &-icon {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    background-position: top right;
    background-repeat: no-repeat;
    width: 270px;
    height: 250px;
    @include l {
      width: 135px;
      height: 125px;
      background-size: contain; } } }
