.loading_center {
  margin-top: 100px; }

.loading {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  background: url(#{$imagesPath}/main_layer_1.png) no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  margin-top: 0px;


  img {
    width: 150px;
    height: 150px;
    animation: mymove 1s infinite linear;
    position: relative;
    top: 50%; } }

