@import './src/assets/scss/_vars_and_mixins.sass';

.dark-mode {
  a, span {
    font-size: 18px !important; } }


.main-navbar {
  background-color: transparent;
  position: absolute;

  .nav-item * {
    white-space: nowrap; }

  a, span {
    color: $white;
    font-family: $PTSans;

    &:hover {
      text-decoration: none; }

    @include media-breakpoint-up(md) {
      font-size: 20px; }

    @include media-breakpoint-up(xl) {
      font-size: 22px; } }

  .navbar-link a.active {
    border-bottom: 1px solid $green; }

  .navbar-hover {
    border-bottom: 1px solid $green; }

  .btn-outline-success {
    max-width: 200px;
    margin-top: -5px; }

  .navbar-toggler {
    .btn {
      border-radius: 0; } }

  .icon {
    display: inline-block;
    margin: 7px 10px 5px 0;
    width: 22px;
    height: 20px;
    @include l {
      margin: 5px 10px 5px 0; } }

  .nav-contact-item-phone {
    background: url(#{$imagesPath}/phone.svg) no-repeat; }

  .nav-contact-item-tg {
    background: url(#{$imagesPath}/telegram.svg) no-repeat; }

  .navbar-brand {
    width: 70px;
    height: 70px;
    padding: 0;
    margin-right: 40px;

    .navbar-logo {
      position: absolute;
      width: 70px;
      height: 120px;
      background-image: url(#{$imagesPath}/logo.svg);
      background-repeat: no-repeat;
      background-size: contain;
      font-size: 0;
      display: inline-block; }

    @include media-breakpoint-up(xl) {
      width: 100px;
      height: 100px;

      .navbar-logo {
        width: 100px;
        height: 170px; } } }


  &.dark-mode {
    background-color: $black;
    position: fixed;
    top: -85px;
    transition: transform 350ms ease-in;
    @include navbar-transform;

    &.fixed-mode {
      top: -75px;
      transform: translateY(74px); }

    .navbar-brand {
      height: 60px;
      width: 60px;

      .navbar-logo {
        background-image: url(#{$imagesPath}/logo-small.svg);
        height: 60px;
        width: 60px; } } } }

.main-navbar.dark-mode {
  .navbar-logo {
    transform: scale(0.8); } }

.navbar.main-navbar.dark-mode {
  padding: 0 1rem; }

.navbar-toggler {
  border-color: transparent !important;

  .btn {
    width: 60px !important;
    height: 60px !important;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px 0 0 1px; } }
