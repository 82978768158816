@import './src/assets/scss/_vars_and_mixins.sass';


.form-actions-group {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  @include l {
    flex-direction: column-reverse;
    align-items: flex-start; } }

.form-file-field {
  color: $white;
  cursor: pointer;
  position: relative;
  font-size: 22px;
  max-width: 60%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 5px 50px 5px;
  margin: 0;
  text-decoration: underline;
  @include l {
    max-width: none;
    padding: 0 42px 15px 0;
    font-size: 16px; }

  &::before {
    cursor: pointer;
    display: block;
    content: "";
    height: 50px;
    width: 50px;
    background-image: url(#{$imagesPath}/pin_2.svg);
    background-position: center;
    position: absolute;
    right: -5px;
    top: 0;
    background-repeat: no-repeat;
    @include l {
      background-size: auto 60%;
      top: -7px; } }

  input[type=file] {
    display: none;
    cursor: pointer;
    opacity: 0; } }

.form, .lets-talk-form {
  position: relative;
  z-index: 100; }

.form-hide {
  opacity: 0;
  position: relative;
  z-index: -1;
  transform: translateY(-60px);
  transition: opacity 300ms ease-in-out, z-index 1s, transform 300ms ease-in-out; }

.form-message {
  max-width: 10em;
  padding-bottom: 50px;
  @include header($white);
  position: absolute;
  top: 0;
  opacity: 0;
  z-index: -1;
  transform: translateY(60px); }

.form-message-show {
  opacity: 1;
  z-index: 100;
  transform: translateY(0);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out; }

.form-preloader {
  width: 100%;
  height: 300px;
  display: block;
  position: absolute;
  top: 0;
  background-image: url(#{$imagesPath}/logotype-white.svg);
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center center;
  opacity: 0.3;
  animation: mymove 2s infinite linear;
  transition: all 500ms ease-in-out; }

.preloader-hide {
  opacity: 0;
  animation: none;
  transition: all 300ms ease-in-out;
  z-index: -1; }

.submit-button-disabled {
  opacity: 0.5; }


.form-group {
  margin-bottom: 0 !important; }

.react-tel-input {
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 56px;
    border-radius: 2px;
    background-color: $white;
    position: absolute;
    top: 0; }

  .flag-dropdown {
    height: 57px !important;
    width: 70px;
    outline: none;
    border: none !important;
    border-right: 1px solid $lightgrey !important;
    background-color: $white;
    z-index: 400;
    @include s {
      height: 56px !important; } }

  .selected-flag {
    width: 100% !important; }

  .arrow {
    left: 18px !important;
    border-left: 3px solid transparent !important;
    border-right: 3px solid transparent !important; }

  .flag {
    transform: scale(2);
    left: 25px; }

  .search {
    background-color: $white !important;
    z-index: 100; }

  .country-list {
    max-height: 350px !important;
    transform: translateX(1px) !important; }

  .country {
    .flag {
      margin-left: 10px; } }

  .country-name {
    margin-left: 20px !important; }

  .form-field {
    padding: 19px 10px 0 85px;
    height: 56px;
    margin-bottom: 32px !important;
    @include s {
      font-size: 16px; }

    &:placeholder-shown:not(:focus) {
      ~ .special-label {
        top: 12px !important;
        font-size: 20px !important;
        @include xl {
          font-size: 20px !important;
          top: 15px !important;
          @include l_1300 {
            font-size: 20px !important;
            top: 15px !important;
            @include l {
              font-size: 17px !important;
              top: 17px !important;
              @include m_900 {
                font-size: 12px !important;
                top: 16px !important;
                @include s {
                  font-size: 18px !important;
                  top: 15px !important;
                  @include xs {
                    font-size: 13px !important;
                    top: 20px !important; } } } } } } } } }

  .special-label {
    display: block !important;
    margin-top: 4px;
    font-size: 12px !important;
    color: $darkgrey;
    font-family: $PTSans;
    left: 85px !important;

    top: 0px !important;
    transition: all 200ms ease-in;
    z-index: 2;
    background-color: transparent !important;
    @include s {
      top: 8px !important; } } }


.form-field-phone {
  background: transparent !important;
  z-index: 3; }

.reCaptcha {
  margin: -8px 0 32px; }



