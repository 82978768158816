@import './src/assets/scss/_vars_and_mixins.sass';


.portfolio-card-face {
  height: 366px;
  width: 100%;
  background-size: cover;
  box-sizing: initial;
  margin: 0 -120px;
  padding: 0 120px;
  @include l {
    height: 300px;
    margin: 0 -80px;
    padding: 0 80px; }
  @include s {
    height: 250px;
    margin: 0 -50px;
    padding: 0 50px; }
  @include xs {
    height: 250px;
    margin: 0 -20px;
    padding: 0 20px; } }

.portfolio-card-face-inner {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 20px;
  box-sizing: border-box;
  @include xs {
    display: table-caption;
    width: 140px; }

  .portfolio-card-location {
    @include basic-black-text;
    text-align: left;
    padding: 9px 0 0 53px;
    //line-height: 54px !important
    background-repeat: no-repeat;
    background-image: url(#{$imagesPath}/location.svg);
    background-position: left center;
    background-size: 60px 54px;
    transform: translateX(-15px);
    @include xs {
      padding: 15px 0 15px 53px; } }

  .portfolio-card-stack-item {
    @include basic-black-text;
    display: inline-block;
    padding: 5px 20px;
    border: 1px solid $green;
    border-radius: 2px;
    margin-right: 15px;
    margin-top: 5px;
    @include s {
      padding: 3px 15px; } } }

.portfolio-card {
  padding: 80px 120px;
  background-color: $white;
  margin: 0 0 50px 0;
  box-sizing: content-box;
  @include l {
    padding: 50px 80px; }
  @include s {
    padding: 30px 50px;
    margin: 0 0 30px 0; }
  @include xs {
    padding: 45px 20px;
    margin: 0 0 30px 0; }


  h3 {
    @include menu-sections-text;
    color: $darkgrey; }

  h2 {
    padding-bottom: 10px; }

  p {
    padding-top: 30px;
    @include basic-black-text;
    line-height: 34px;
    @include l {
      line-height: 28px; }
    @include s {
      line-height: 24px; }

    span {
      font-weight: 700; } }

  ul {
    margin: 0;
    padding: 30px 40px 0;

    li {
      margin: 0;
      padding: 0;
      @include basic-black-text;
      line-height: 34px;
      @include l {
        line-height: 28px; }
      @include s {
        line-height: 24px; } } } }

.portfolio-card-inactive {
  box-shadow: 0px -3px 0px $ash; }

.portfolio-card-active {
  box-shadow: 0px -3px 0px $green; }
