@import './src/assets/scss/_vars_and_mixins.sass';

.form-group {
  width: 100%;
  position: relative;

  input {
    height: 56px; }

  textarea {
    height: 115px;
    resize: none;

    ~ .input-message {
      bottom: 13px !important;
      @include l {
        bottom: 1px !important; } } } }

.form-field-label {
  @include form-fields-labels;
  color: grey;
  cursor: text;
  position: absolute;
  top: 6px;
  left: 21px;
  transition: all 200ms ease-in;
  @include s {
    font-size: 12px; } }


.form-field {
  @include form-fields-labeled-texting;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 2px 0 $white;
  border: none;
  border-radius: 2px;
  background: $white;
  margin-bottom: 32px;
  padding: 20px 20px 0;
  position: relative;
  @include s {
    font-size: 16px; }


  &::placeholder {
    color: transparent;
    transition: all 200ms; }

  &:focus {
    outline: none; }

  &:placeholder-shown:not(:focus) {
    + .form-field-label {
      top: 19px;
      font-size: 22px;
      @include l {
        top: 20px;
        font-size: 16px; } } } }

.valid-input {
  box-shadow: 0 2px 0 $green !important; }

.invalid-input {
  box-shadow: 0 2px 0 $red !important;
  background-color: $lightRed;

  ~ .input-message {
    color: $red;
    line-height: unset;
    overflow: auto;
    opacity: 1;
    transition: opacity 200ms ease-in-out; } }

.input-message {
  color: $white;
  position: absolute;
  display: block;
  bottom: 6px;
  line-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  @include l {
    font-size: 12px;
    bottom: 12px; } }

.submit-button {
  padding: 9px 60px 13px;
  font-family: $PTSans;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.25);
  background: $green;
  border-radius: 2px;
  border: none;
  @include l {
    font-size: 18px; } }
