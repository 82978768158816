@import './src/assets/scss/_vars_and_mixins.sass';

#promo {
  background: url(#{$imagesPath}/main_layer_1.png) no-repeat center center fixed;
  background-size: cover;
  height: 600px;
  position: relative;

  @include l {
    height: 565px; }
  @include s {
    height: 483px; }

  .container {
    height: 100%;
    position: relative; }

  h1 {
    font-family: $Oswald;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: $white;
    padding-top: 253px;
    z-index: 100;
    position: relative;
    @include l {
      font-size: 46px;
      line-height: 56px; }
    @include m {
      font-size: 34px;
      line-height: 44px;
      padding-top: 182px;
      max-width: 32vw; }
    @include s {
      font-size: 24px;
      line-height: 34px;
      padding-top: 190px;
      z-index: 1001 !important;
      position: relative; } }

  .link-box {
    padding-top: 80px;
    position: absolute;
    bottom: 82px;
    left: 40px;
    @include l {
      bottom: 60px; }
    @include s {
      left: auto; } }

  &::after {
    content: '';
    width: 916px;
    height: 700px;
    display: block;
    background-image: url(#{$imagesPath}/cosmonaut.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
    position: absolute;
    right: 0;
    top: 75px;
    z-index: 1000;
    @include xl {
      width: 65vw; }

    @include l_1300 {
      width: 55vw; }
    @include l {
      width: 606px;
      height: 500px;
      background-position: right -10%; }
    @include m {
      width: 65vw; }
    @include s {
      width: 452px;
      top: 72px;
      background-position: 151px 0; } } }

.promo-link {
  height: 28px;
  width: 28px;
  display: inline-block;
  margin: 0 35px 0 0;
  background-size: contain !important;
  @include l {
    margin: 0 25px 0 0; }

  &.promo-link-fb {
    background: url(#{$imagesPath}/social-link-fb.svg) no-repeat center center; }

  &.promo-link-ig {
    background: url(#{$imagesPath}/social-link-ig.svg) no-repeat center center; }

  &.promo-link-in {
    background: url(#{$imagesPath}/social-link-in.svg) no-repeat center center; }

  &.promo-link-tw {
    background: url(#{$imagesPath}/social-link-tw.svg) no-repeat center center; } }

