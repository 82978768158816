@import './src/assets/scss/_vars_and_mixins.sass';

.portfolio-header {
  @include medium-header($black);
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.005em;
  text-transform: uppercase;
  @include l {
    line-height: 28px; } }
