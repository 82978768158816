@import './src/assets/scss/_vars_and_mixins.sass';

.header-text {
  @include basic-white-text;
  font-weight: 700;
  text-align: center;
  padding: 30px 20px;
  width: 100%;
  @include xs {
    padding: 15px 20px; } }
