@import 'assets/scss/_vars_and_mixins';
@import 'assets/scss/_custom_bootstrap';
@import 'assets/scss/_general';
@import 'components/UI/LargeHeader/LargeHeader';
@import 'components/UI/MediumHeader/MediumHeader';
@import 'components/UI/PortfolioHeader/_portfolioHeader.sass';
@import 'components/UI/HeaderText/_headerText.sass';
@import 'components/UI/ScrollSpyMenu/_scrollSpyMenu.sass';
@import 'components/UI/PortfolioCard/_portfolioCard.sass';
@import 'components/UI/ProcessCard/_processCard.sass';

@import 'components/Promo/_promo';
@import 'components/WhatWeDo/_whatWeDo';
@import 'components/AboutUsParts/_aboutUsParts';
@import 'components/UI/FormElement/_formElement';
@import 'components/ContactForm/_contact_form.sass';
@import 'components/Technology/_technology';
@import 'components/CaseStudy/_caseStudy';
@import 'components/Pricing/_pricing';
@import 'components/LetsTalk/_letsTalk';
@import 'components/MainNavbar/_mainNavbar';
@import 'components/HeaderBlock/_headerBlock';
@import 'components/Conferences/_conferences';
@import 'components/Team/_team';
@import 'components/Newcomers/_newcomers';
@import 'components/ProcessesBlock/processes_block';
@import 'containers/CaseStudiesPage/_caseStudiesPage';
@import 'containers/AboutUs/_aboutUs';
@import "components/UI/File/_File.sass";
@import "components/loading/_Loading.sass";
