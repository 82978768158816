@import './src/assets/scss/_vars_and_mixins.sass';

.processes {
  .portfolio-card {
    position: relative; }

  .portfolio-header {
    padding-bottom: 0;
    position: relative;
    z-index: 2;

    span {
      @include green-line(20px, -36px);
      z-index: -1 !important;
      @include l {
        @include green-line(13px, -36px);
        @include m {
          @include green-line(12px, -36px); } } } }

  .process-planet {
    width: 300px;
    height: 200px;
    display: block;
    position: absolute;
    right: -20px;
    top: -35px;
    background-repeat: no-repeat;
    background-position: right top;
    @include l {
      transform: scale(0.7);
      right: -40px;
      top: -70px; }
    @include m {
      right: -80px;
      top: -70px; }
    @include s {
      transform: scale(0.5); } }

  .side-block {
    @include m {
      display: none; } } }
