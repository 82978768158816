@import './src/assets/scss/_vars_and_mixins.sass';

.newcomers-wrapper {
  .masonry {
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
    gap: 7px;

    p {
      position: relative;
      @include basic-black-text;

      span {
        @include green-line(15px, -39px);
        z-index: -1;
        @include l {
          @include green-line(16px, -39px);
          z-index: -1; } } }


    @include media-breakpoint-up(md) {
      height: 440px;
      p {
        width: 40%;

        &:nth-child(2n) {
          order: 2; }

        &:nth-child(2n+1),
        &:nth-child(2) {
          order: 1; } }

      &::before {
        content: "";
        flex-basis: 100%;
        width: 0;
        order: 2; } }

    @include media-breakpoint-up(lg) {
      height: 350px;
      p {
        width: 27%;

        &:nth-child(3n+1) {
          order: 1; }

        &:nth-child(3n+2),
        &:nth-child(7) {
          order: 2; }

        &:nth-child(3n) {
          order: 3; } }

      &::before,
      &::after {
        content: "";
        flex-basis: 100%;
        width: 0;
        order: 2; } }

    @include media-breakpoint-up(xl) {
      height: 300px; } }

  .white-bg {
    background-color: white;
    height: 100%;
    width: 115%;
    position: absolute;
    z-index: -1;
    margin-left: -80px;
    @include m {
      width: 96%;
      @include s {
        width: 95%; } } }

  .large-header {
    max-width: 12em; }

  .header-text {
    color: $black; } }

.about-us-form {
  padding: 140px 0 70px 90px;
  position: relative;
  @include l {
    padding: 45px 20px; }

  h4 {
    @include white-header;
    padding-bottom: 20px; }

  > div {


    width: 100% !important;
    box-sizing: border-box;
    @include s {
      width: 100%; } }

  .join-us-text {
    @include basic-white-text;
    padding: 0 0 25px 0; }

  .form-message-show {
    top: 200px; }

  .form-preloader {
    top: 200px !important;
    left: 0 !important; } }

.intern-header {
  padding-top: 100px !important;
  @include m {
    padding-top: 50px !important; } }

.newcomers-left {
  background: linear-gradient(90deg, $white 56%, $lightGreyBg 51%);
  @include m {
    background: linear-gradient(90deg, $white 100%, $lightGreyBg 51%); }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    @include s {
      flex-direction: column; } } }

.newcomers-right {
  position: relative;
  transform: translateY(0px);
  width: 41%;
  @include l {
    transform: translateY(15px);
    width: 45%; }
  @include m {
    transform: translateY(9px); }
  @include s {
    width: 100%;
    transform: none;
    background-color: $black;
    @include overflow-from-container; }

  .join-us {
    display: block;
    width: 100vw;
    height: 972px;
    background-color: $black;
    position: absolute;
    left: 0;
    top: 70px;
    @include xl {
      height: 1010px; }
    @include l {
      top: 20px;
      height: 915px; }
    @include m {
      top: 0;
      left: -25px;
      height: 900px; }
    @include s {
      top: 0;
      left: -25px;
      height: 842px; } } }

.intern-applicants {
  padding-bottom: 280px;
  @include xl {
    padding-bottom: 325px; }
  @include l {
    padding-bottom: 260px; }
  @include m {
    padding-bottom: 50px; }

  p {
    @include basic-black-text; } }

.notebook {
  @include s {
    width: 50vw !important;
    margin: 20px auto !important;
    display: block; } }
