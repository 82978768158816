.file {
  display: flex;
  background-color: white;
  margin-top: 30px;
  margin-bottom: 20px;
  border-radius: 2px;

  > input {
    font-family: "PT Sans", sans-serif;
    width: 100%;
    height: 60px;
    outline: none;
    border: none;
    font-size: 20px;
    padding-left: 20px; }
  .form {
    font-family: "PT Sans", sans-serif;
    color: gray;
    cursor: pointer;
    position: relative;
    font-size: 22px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 10px 54px 0px 22px;
    margin: 0;
    > input {
      opacity: 0;
      position: absolute;
      z-index: -1; } }

  .form:before {
    cursor: pointer;
    display: block;
    content: "";
    height: 36px;
    width: 36px;
    background-image: url(#{$imagesPath}/screpka.png);
    background-position: center;
    position: absolute;
    right: 20px;
    top: 9px;
    background-size: cover;
    background-repeat: no-repeat; } }

.text_warning_link {
  color: #F00000;
  line-height: unset;
  overflow: auto;
  opacity: 1;
  transition: opacity 200ms ease-in-out;
  margin-top: -20px;
  margin-bottom: 12px; }
