@import './src/assets/scss/_vars_and_mixins.sass';


body {
  min-width: 320px;
  background-color: $lightGreyBg; }

.main-page, #about-us {
  overflow-x:  hidden !important; }

.container {
  max-width: 1580px;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  min-width: 320px;
  margin: 0 auto;
  @include s {
    padding-left: 20px;
    padding-right: 20px; } }

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-weight: normal; }

h4.section-title {
  @include black-header; }

p {
  font-family: $PTSans;
  font-size: 22px;
  line-height: 34px;
  font-weight: normal; }
