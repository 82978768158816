@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600&family=PT+Sans:wght@300;400;700&family=PT+Sans+Narrow&display=swap');

$imagesPath: '/assets/images';

$white: #ffffff;
$black: #000000;
$lightgrey: rgba(0,0,0,0.3);
$darkgrey: rgba(0,0,0,0.5);
$ash: #dddddd;
$green: #00ff36;
$lightgreen: #00F941;
$lightGreyBg: #F5F5F5;
$deepBlue: #04012B;
$red: #F00000;
$lightRed: #FFEEEE;

$Oswald: 'Oswald', sans-serif;
$PTSans: 'PT Sans', sans-serif;
$PTSansNarrow: 'PT Sans Narrow', sans-serif;

@mixin header($color) {
  font-family: $Oswald;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  //line-height: 58px
  letter-spacing: 0.005em;
  text-transform: uppercase;
  color: $color;
  @include l {
    font-size: 36px;
 }    //line-height: 46px
  @include m {
    font-size: 24px;
 } }    //line-height: 34px

@mixin medium-header($color) {
  font-family: $Oswald;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  //line-height: 147%
  letter-spacing: 0.005em;
  text-transform: uppercase;
  color: $color;
  @include l {
    font-size: 24px; }
  @include m {
    font-size: 18px; } }

@mixin small-header($color) {
  font-family: $Oswald;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  //line-height: 34px
  letter-spacing: 0.005em;
  text-transform: uppercase;
  color: $color; }

@mixin basic-text($color, $fontSize) {
  font-family: $PTSans;
  font-style: normal;
  font-weight: normal;
  color: $color;
  font-size: $fontSize;
  @include l {
    font-size: 18px;
 }    //line-height: 28px
  @include m {
    font-size: 16px;
 } }    //line-height: 24px

@mixin white-header {
  @include header($white); }

@mixin black-header {
  @include header($black); }

@mixin black-smaller-header {
  @include medium-header($black); }

@mixin basic-nav-white-text {
  @include basic-text($white, 22px);
 }  //line-height: 145%

@mixin basic-black-text {
  @include basic-text($black, 22px);
 }  //line-height: 211%

@mixin basic-white-text {
  @include basic-text($white, 22px);
 }  //line-height: 156%

@mixin partners-names-text {
  font-size: 14px;
  font-family: $PTSansNarrow;
  //line-height: 114%
  text-align: center;
  letter-spacing: 0.005em;
  text-transform: uppercase;
  color: $lightgrey; }

@mixin menu-sections-text {
  font-family: $Oswald;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  //line-height: 34px
  letter-spacing: 0.005em;
  text-transform: uppercase;
  @include l {
    font-size: 16px;
 } }    //line-height: 26px


@mixin form-fields-labeled-texting {
  @include basic-text($black, 22px);
  @include l {
    font-size: 18px; } }

@mixin form-fields-labels {
  font-family: $PTSans;
  font-style: normal;
  font-weight: normal;
  color: $darkgrey;
  font-size: 12px;
 }  //line-height: 16px

@mixin line($color) {
  display: block;
  width: 50px;
  height: 5px;
  background: $color;
  position: absolute;
  z-index: 1; }

@mixin green-line($top, $left) {
  @include line($green);
  top: $top;
  left: $left; }

@mixin grey-line($top, $left) {
  @include line($ash);
  top: $top;
  left: $left; }


@mixin overflow-from-container {
  box-sizing: initial;
  margin: 0 -20vw;
  padding: 0 20vw; }

@mixin opacity-bg-image {
  opacity: 0.8; }

@mixin xl {
  @media (max-width: 1600px) {
    @content; } }

@mixin l_1300 {
  @media (max-width: 1300px) {
    @content; } }

@mixin l {
  @media (max-width: 1200px) {
    @content; } }

@mixin m {
  @media (max-width: 1000px) {
    @content; } }

@mixin navbar-transform {
  @media (max-width: 1000px) {
    @content; } }

@mixin m_900 {
  @media (max-width: 900px) {
    @content; } }

@mixin s {
  @media (max-width: 720px) {
    @content; } }

@mixin xs {
  @media (max-width: 500px) {
    @content; } }

@keyframes mymove {
  from {
    transform: rotate(360deg); }
  to {
    transform: rotate(0deg); } }
