@import './src/assets/scss/_vars_and_mixins.sass';

#what-we-do {
  padding: 100px 0 140px 0;
  background: $lightGreyBg;
  position: relative;
  @include l {
    padding: 80px 0 95px 0; }
  @include s {
    padding: 50px 0 0 0; }

  &::after {
    content: '';
    width: 440px;
    height: 407px;
    display: block;
    position: absolute;
    right: 0;
    bottom: -200px;
    background-image: url(#{$imagesPath}/laptops-and-planet.webp);
    background-repeat: no-repeat;
    z-index: 3;
    background-position: right top;
    @include opacity-bg-image;
    @include xl {
      background-size: 80%; }
    @include l {
      background-size: 66%;
      bottom: -250px; }
    @include m {
      background-size: 55%;
      bottom: -275px; }
    @include s {
      bottom: -338px;
      background-size: 63%; }
    @include xs {
      background-size: 50vw; } }


  h4 {
    padding-bottom: 12px; }

  h2 {
    max-width: 21.5em;
    padding-bottom: 50px;
    @include l {
      padding-bottom: 40px; }
    @include s {
      max-width: 16em;
      padding-bottom: 16px; } } }

.what-we-do-items-box {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  font-size: 22px;
  z-index: 4;
  position: relative; }

.what-we-do-item {
  width: 33.3%;
  padding-bottom: 1px;
  position: relative;
  @include m {
    width: 50%; }
  @include s {
    width: 100%; }

  p {
    @include basic-black-text;
    z-index: 2;
    position: relative;
    margin: 0 45px 0 0;
    @include xl {
      margin: 0 0 0 39px; }
    @include l {
      line-height: 28px;
      margin: 9px 0 0 39px; }
    @include m {
      font-size: 16px;
      line-height: 36px;
      margin: 0 0 0 39px; }
    @include s {
      line-height: 35px; } }


  span {
    @include green-line(15px, -40px);
    @include xl {
      @include green-line(15px, 1px); }
    @include l {
      @include green-line(20px, -1px); }
    @include m {
      @include green-line(16px, -1px); }
    @include s {
      @include green-line(15px, -1px); } } }
