@import './src/assets/scss/_vars_and_mixins.sass';

#header-block {
  background: url(#{$imagesPath}/main_layer_1.png) no-repeat center center fixed;
  background-size: cover;
  height: 500px;
  @include l {
    height: 565px; }
  @include s {
    height: 483px; }


  h2 {
    text-align: center;
    font-family: $Oswald;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: $white;
    padding-top: 200px;
    @include l {
      font-size: 46px;
      line-height: 56px;
      max-width: 90vw; }
    @include m {
      font-size: 34px;
      line-height: 44px;
      padding-top: 180px; }
    @include s {
      font-size: 24px;
      line-height: 34px;
      padding-top: 160px; } }

  p {
    text-align: center; } }

