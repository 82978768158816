@import 'src/assets/scss/_vars_and_mixins';

.team-wrapper {
  img {
    width: 100%;
    max-width: 310px;
    max-height: 264px; }

  .management {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }

  .team {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }

  .member-block {
    @include basic-black-text; } }

.wrapper-img {
  position: relative;
  max-width: 300px; }

.wrapper-img .img-hide {
  opacity: 0;
  display: none; }

.wrapper-img:hover .img-hide {
  display: block;
  opacity: 1;
  position: absolute;
  height: 272px;
  top: 0;
  left: 0;
  background-color: #f5f5f5; }



