@import './src/assets/scss/_vars_and_mixins.sass';

.technology-block {
  background-color: $lightGreyBg;
  padding: 60px 0 0;
  @include l_1300 {
    padding: 100px 0 0; }
  @include l {
    padding: 20px 0 0; }
  @include s {
    padding: 10px 0; }

  .container {
    display: flex;
    flex-wrap: wrap; } }

.technology-block-info {
  width: 33.8%;
  @include m {
    width: 50%; }
  @include s {
    width: 100%; }

  h4 {
    @include black-header; }

  p {
    @include basic-black-text;
    line-height: 34px;
    @include l {
      line-height: 28px; }
    @include s {
      line-height: 24px; } } }

.technology-text {
  padding: 50px 0 517px;
  max-width: 16em;
  position: relative;
  @include l {
    max-width: 12em;
    padding: 64px 0 346px; }
  @include m {
    max-width: 15em;
    padding: 64px 0 550px; }
  @include s {
    padding: 64px 0 385px;
    max-width: none; }
  @include xs {
    padding: 23px 0 385px; }

  &::after {
    content: '';
    width: 460px;
    height: 476px;
    display: block;
    position: absolute;
    left: -60px;
    bottom: 20px;
    background-image: url(#{$imagesPath}/cube-and-technologies.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    @include opacity-bg-image;
    @include l_1300 {
      background-size: 77%; }
    @include l {
      height: 323px;
      width: 384px;
      background-size: 77%; }
    @include m {
      height: 400px;
      width: 47vw;
      background-size: 86%;
      bottom: 80px; }
    @include s {
      bottom: 22px;
      width: 100%;
      height: 342px;
      left: 50%;
      transform: translateX(-50%);
      background-size: contain; }
    @include xs {
      height: 300px; } } }


.learn-more {
  max-width: 15em !important;
  @include l {
    max-width: 13em !important; }
  @include m {
    max-width: 15em !important; }
  @include s {
    max-width: none !important; } }

.learn-more-mobile {
  display: none;
  @include s {
    display: block;
    @include basic-black-text;
    line-height: 24px; } }

.learn-more-desktop {
  @include s {
    display: none; } }


.technologies {
  width: 66.2%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 885px;
  padding-top: 15px;
  @include l {
    box-sizing: border-box;
    max-height: 811px; }
  @include m {
    width: 50%;
    max-height: 1150px; }
  @include s {
    width: 100%;
    max-height: 790px; }
  @include xs {
    max-height: 1100px; } }

.technology-box {
  width: 33.3%;
  box-sizing: border-box;
  padding-bottom: 50px;
  position: relative;
  @include m {
    width: 50%; }
  @include s {
    width: 33.3%; }
  @include xs {
    width: 50%; }

  &:nth-child(2) {
    @include l {
      padding-bottom: 100px; }
    @include m {
      padding-bottom: 50px; }
    @include s {
      padding-bottom: 100px; }
    @include xs {
      padding-bottom: 50px; } }

  &:nth-child(5) {
    @include l {
      padding-right: 46px; } }

  span {
    @include green-line(16px, -40px);
    @include l {
      @include green-line(13px, -40px); }
    @include m {
      @include green-line(8px, -40px); }
    @include s {
      @include green-line(9px, -10px); } }

  h5 {
    @include black-smaller-header;
    padding-bottom: 30px;
    position: relative;
    z-index: 2;

    .absolute-text {
      display: block;
      padding: 0;
      position: absolute;
      top: 33px;
      left: 0;
      font-family: $Oswald;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      letter-spacing: 0.005em;
      text-transform: uppercase;
      color: $black;
      @include l {
        top: 25px; }
      @include m {
        font-size: 18px; } } }


  p {
    @include basic-black-text;
    line-height: 37px;
    padding-left: 30px;
    background-repeat: no-repeat;
    @include l {
      line-height: 30px; }
    @include s {
      line-height: 28px; } } }

.standard-link {
  color: $black;
  text-decoration: underline;

  &:hover {
    text-decoration: none; }

  &:visited {
    color: $black; } }


.technology-item-react {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -1998px;
  @include s {
    background-position: -20px -2004px; } }

.technology-item-redux {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -2062px;
  @include s {
    background-position: -20px -2068px; } }

.technology-item-angular {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -206px;
  @include s {
    background-position: -20px -212px; } }

.technology-item-python {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -1936px;
  @include s {
    background-position: -20px -1940px; } }

.technology-item-js {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -1422px;
  @include s {
    background-position: -20px -1428px; } }

.technology-item-php {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -1806px;
  @include s {
    background-position: -20px -1812px; } }

.technology-item-ruby {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -2126px;
  @include s {
    background-position: -20px -2132px; } }

.technology-item-ruby-on-rails {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -2190px;
  @include s {
    background-position: -20px -2196px; } }

.technology-item-node-js {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -1742px;
  @include s {
    background-position: -20px -1748px; } }

.technology-item-golang {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -1230px;
  @include s {
    background-position: -20px -1236px; } }

.technology-item-laravel {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -1678px;
  @include s {
    background-position: -20px -1684px; } }

.technology-item-atlassian-jira {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -462px;
  @include s {
    background-position: -20px -468px; } }

.technology-item-slack {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -2446px;
  @include s {
    background-position: -20px -2452px; } }

.technology-item-trello {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -2702px;
  @include s {
    background-position: -20px -2708px; } }

.technology-item-github {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -1102px;
  @include s {
    background-position: -20px -1108px; } }

.technology-item-kotlin {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -1550px;
  @include s {
    background-position: -20px -1556px; } }

.technology-item-flutter {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -1038px;
  @include s {
    background-position: -20px -1044px; } }

.technology-item-java {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -1294px;
  @include s {
    background-position: -20px -1300px; } }

.technology-item-swift {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -2510px;
  @include s {
    background-position: -20px -2516px; } }

.technology-item-android-studio {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -142px;
  @include s {
    background-position: -20px -148px; } }

.technology-item-figma {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -2830px;
  @include s {
    background-position: -20px -2836px; } }

.technology-item-sketch {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -2382px;
  @include s {
    background-position: -20px -2388px; } }

.technology-item-adobe-illustrator {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -14px;
  @include s {
    background-position: -20px -20px; } }

.technology-item-adobe-photoshop {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -78px;
  @include s {
    background-position: -20px -84px; } }

.technology-item-aws {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -526px;
  @include s {
    background-position: -20px -532px; } }

.technology-item-digital-ocean {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -846px;
  @include s {
    background-position: -20px -852px; } }

.technology-item-kubernetes {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -1614px;
  @include s {
    background-position: -20px -1620px; } }

.technology-item-ansible {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -270px;
  @include s {
    background-position: -20px -276px; } }

.technology-item-gitlabci {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -1166px;
  @include s {
    background-position: -20px -1172px; } }

.technology-item-terraform {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -2574px;
  @include s {
    background-position: -20px -2580px; } }

.technology-item-jenkins {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -1358px;
  @include s {
    background-position: -20px -1364px; } }

.technology-item-bamboo {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -590px;
  @include s {
    background-position: -20px -596px; } }

.technology-item-circleci {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -782px;
  @include s {
    background-position: -20px -788px; } }

.technology-item-travisci {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -2638px;
  @include s {
    background-position: -20px -2644px; } }

.technology-item-elk {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -974px;
  @include s {
    background-position: -20px -980px; } }

.technology-item-sentry {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -2254px;
  @include s {
    background-position: -20px -2260px; } }

.technology-item-prometheus {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -1870px;
  @include s {
    background-position: -20px -1876px; } }

.technology-item-bitbucket {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -654px;
  @include s {
    background-position: -20px -660px; } }

.technology-item-apache-nifi {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -398px;
  @include s {
    background-position: -20px -404px; } }

.technology-item-kafka {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -1486px;
  @include s {
    background-position: -20px -1492px; } }

.technology-item-yandex-clickhouse {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -2766px;
  @include s {
    background-position: -20px -2772px; } }

.technology-item-apache-hadoop {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -334px;
  @include s {
    background-position: -20px -340px; } }

.technology-item-sip-freeswitch-asterisk {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -2318px;
  @include s {
    background-position: -20px -2324px; } }

.technology-item-ci-cd {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -718px;
  @include s {
    background-position: -20px -724px; } }

.technology-item-docker {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -910px;
  @include s {
    background-position: -20px -916px; } }

.technology-item-kubeflow {
  background-size: auto;
  background-image: url(#{$imagesPath}/technologies/css-sprites-technologies.png);
  background-position: -20px -2894px;
  @include s {
    background-position: -20px -2900px; } }
