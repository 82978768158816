@import './src/assets/scss/_vars_and_mixins.sass';

.pricing-block {
  padding: 90px 0 150px;
  background-color: $lightGreyBg;
  @include l {
    padding: 70px 0 120px; }
  @include s {
    padding: 40px 0 80px; }

  h4 {
    @include header($black);
    text-align: center;
    padding-bottom: 50px; } }

.pricing-box {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box; }


.pricing-item {
  border-right: 1px solid #C4C4C4;
  width: 25%;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center 52px;
  padding: 0 25px;
  transition: background-color 100ms ease-in-out;
  @include m {
    width: 50%; }

  &:hover {
    background-color: $white;
    transition: background-color 100ms ease-in-out; }


  &:last-child {
    border-right: none; }

  &:nth-child(2) {
    @include m {
      border-right: none; } }

  &:nth-child(-n+2) {
    @include m {
      margin-bottom: 10px; } }

  h5 {
    @include medium-header($black);
    text-align: center;
    padding: 183px 0 20px;
    max-width: 7.5em;
    margin: 0 auto;
    height: 88px;
    position: relative;
    box-sizing: content-box;
    line-height: 147%;
    @include l {
      height: 66px; }

    > span {
      font-weight: 600;
      position: absolute;
      bottom: 43px;
      left: 50%;
      transform: translateX(-50%);
      @include l {
        bottom: 34px; }
      @include m {
        bottom: 47px; } } }

  > p {
    @include medium-header($black);
    font-weight: 400;
    text-align: center; }

  > span {
    @include basic-black-text;
    line-height: 34px;
    display: block;
    text-align: center;
    text-transform: none;
    padding-bottom: 50px; }


  .pricing-info {
    min-height: 312px;
    @include l {
      min-height: 292px; }
    @include m {
      min-height: 239px; }

    p {
      @include basic-black-text;
      line-height: 34px;
      text-align: center;
      margin: 0 auto;
      max-width: 13.5em;
      padding-bottom: 35px; } } }

.get-quote-btn {
  @include medium-header($black);
  font-weight: 400;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none; } }

.pricing-item-1 {
  background-image: url(#{$imagesPath}/price-ranges-icons/developer.svg); }

.pricing-item-2 {
  background-image: url(#{$imagesPath}/price-ranges-icons/developer-and-designer.svg); }

.pricing-item-3 {
  background-image: url(#{$imagesPath}/price-ranges-icons/2-developers-and-pm.svg); }

.pricing-item-4 {
  background-image: url(#{$imagesPath}/price-ranges-icons/individual-team.svg); }
